(function handleTogglePassword() {
  window.addEventListener("load", () => {
    const eyeSlashIcon = document.querySelector(".eye-slash-icon");
    const eyeSolidIcon = document.querySelector(".eye-solid-icon");
    const passwordInput = document.querySelector(".password-input");

    const togglePassword = () => {
      const currentlyHidden = passwordInput.getAttribute("type") === "password";

      if (currentlyHidden) {
        passwordInput.setAttribute("type", "text");
        eyeSlashIcon.classList.add("hidden");
        eyeSolidIcon.classList.remove("hidden");
      } else {
        passwordInput.setAttribute("type", "password");
        eyeSlashIcon.classList.remove("hidden");
        eyeSolidIcon.classList.add("hidden");
      }
    };

    eyeSlashIcon.addEventListener("click", togglePassword);
    eyeSolidIcon.addEventListener("click", togglePassword);
  });
})();
